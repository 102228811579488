.jumbo {

	@include media-breakpoint-up(lg) {
		.jumbo_marketing { padding-right: 5px; }
		.jumbo_conversion { padding-left: 5px; }
	}

}

	.jumbo_marketing {

		.carousel {
			border: 1px solid #ddd;
			background-color: $white;
			padding: 5px;
		}

		@include media-breakpoint-down(md) {
			margin-bottom: 1rem;
		}

		@include media-breakpoint-only(lg) {

			.carousel .carousel-caption {
			    position: relative;
			    padding-top: 1.1rem;
			    padding-bottom: 0.8rem;
			}

		}

	}