$announce-bg: 				$color-secondary !default;
$announce-color: 			$white !default;

.announce {

	padding: 12px 0;
	text-align: center;
	border-bottom: 0.25rem solid $white;
	box-shadow: 0 2px 5px 0 rgba(0,0,0,0.5);
	background-color: $announce-bg;
	color: $announce-color;

	p {
		margin-bottom: 10px;
		font-weight: 800;
		line-height: 1;
		text-transform: uppercase;
		&:last-child { margin-bottom: 0; }
		b { font-size: 0.85rem; }
	}

	@include media-breakpoint-up(md) {
		.wrap {
			display: flex;
			flex-wrap: nowrap;
			align-items: center;
			justify-content: center;
			p {
				width: percentage(1/3);
				margin-bottom: 0;
				&:nth-child(2) { padding: 0 15px; }
			}
		}
	}

}


