$banner-bg: 							$white !default;
$banner-color: 							$color-text !default;

.banner {

	padding: 0 0 20px;
	background-color: $banner-bg;
	color: $banner-color;

	.row { @extend %clearfix; }

	@include media-breakpoint-up(md) {
		padding-bottom: 0;
		.site_branding {
			.branding_logo {
				margin: 20px 0;
				width: 200px;
				height: 129px;
			}
		}		
		.site_best {
			.branding_best {
				max-width: 180px;
				margin: 0 auto;
			}
		}
	}


	@include media-breakpoint-up(lg) {

		.menu_main {

			.nav_menu { text-align: right; }

			.nav_menu > .menu_item {
				display: inline-block;
				float: none;
				width: auto;
				margin-right: 0;
			}			

			.nav_menu > .menu_item > .menu_link {
				padding: 15px 10px;
				white-space: nowrap;
			}

			.caret { margin-left: 8px; }

		}

	}

}