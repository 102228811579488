.block-main {
	padding: 1.5em 0;
	clear: both;
	.block_title { margin-top: 0; }
}


.block.large-projects {
	padding: 30px 0;
	background-color: #fff;

	img {
		display: inline-block;
		height: 115px;
		width: auto;
		margin: 0;
	}

	img+img { margin-left: 15px; }

	@include media-breakpoint-up(md) {
		.row { align-items: center; }
		.lp-content { @include make-col(8); }
		.lp-media { @include make-col(4); }
	}
	
}