$mm_bg: 						transparent; //$white !default;
$mm_item_count: 				5 !default;
$mm_item_margin:				(1 * $mm_item_count);
$mm_item_width: 				((100 - $mm_item_margin) / $mm_item_count) * 1%;

$mm_link_bg:					$color-primary;
$mm_link_color:					$white;
$mm_hover_bg:					lighten($color-primary, 15%);
$mm_hover_color:				$white;
$mm_active_bg:					$color-secondary;
$mm_active_color:				$white;

$mm_dropdown_link_bg: 			$mm_hover_bg;
$mm_dropdown_link_color: 		$white;
$mm_dropdown_hover_bg: 			rgba(255,255,255,0.3);
$mm_dropdown_hover_color: 		$white;
$mm_dropdown_active_bg: 		$white;
$mm_dropdown_active_color: 		$color-primary;
$mm_dropdown_divider_dark: 		1px solid rgba(0,0,0,0.5);
$mm_dropdown_divider_lite:		1px solid rgba(255,255,255,0.5);

.menu_main {

	padding-bottom: 15px;
	background-color: $mm_bg;

	.nav_menu > .menu_item {
		width: $mm_item_width;
		margin-right: ($mm_item_margin / $mm_item_count) * 1%;
		&:last-child { margin-right: 0; }
	}

	.menu_item {
		&.open {
			> .menu_link {
				@if $mm_hover_bg { background-color: $mm_hover_bg !important; }
				@if $mm_hover_color { color: $mm_hover_color !important; }
				outline: none;
			}
			.dropdown_menu {
				background-color: $mm_hover_bg;
			}
		}
		&.active .menu_link {
			outline: none;
			background-color: $mm_active_bg !important;
			color: $mm_active_color !important;
		}	
	}

		.nav_menu > .menu_item > .menu_link {
			font-size: 14px;
			background-color: $mm_link_bg;
			color: $mm_link_color;
			&:hover, &:focus {
				outline: none;
				background-color: $mm_hover_bg;
				color: $mm_hover_color;
			}
		}

		.dropdown_menu {

			.menu_item {
				&:not(:last-child) { border-bottom: $mm_dropdown_divider_dark; }
				&:not(:first-child) { border-top: $mm_dropdown_divider_lite; }
				&.active .menu_link {
					outline: none;
					background-color: $mm_dropdown_active_bg !important;
					color: $mm_dropdown_active_color !important;
				}
			}

				.menu_link {
					background-color: $mm_dropdown_link_bg;
					color: $mm_dropdown_link_color;
					&:hover, &:focus {
						outline: none;
						background-color: $mm_dropdown_hover_bg;
						color: $mm_dropdown_hover_color;					
					}
				}

		}

}