.block-main.socials {

	padding: 30px 0 10px !important;
	background-color: transparent;

	img {
		max-width: 300px;
		margin: 0 auto;
	}

	ul {
		overflow: hidden;
		text-align: center;
		li { margin: 0; padding: 15px; }
	}	

	@include media-breakpoint-only(sm) {
		ul > li:last-child {
			float: none;
			margin: 0 auto;
		}
	}

	@include media-breakpoint-up(md) {
		ul > li {
			display: block;
			float: left;
			width: percentage(1/5);
			padding: 5px;
		}
	}

}