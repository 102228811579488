$logo_width: 			200px;
$logo_height: 			129px;
$logo_width-sm: 		278px;
$logo_height-sm:		179px;
$logo_path: 			url("/_/images/layout/tv-plumbing.png");

.site_branding {

	text-align: center;

	@include media-breakpoint-up(md) {
		text-align: left;
		.branding_logo {
			margin: 15px 0;
		}
	}

}

	.branding_logo {
		display: block;
		box-sizing: border-box;
		width: $logo_width;
		height: $logo_height;
		margin: 15px auto 15px;
		padding: 15px;
		text-indent: -9999px;
		background-image: $logo_path;
		background-repeat: no-repeat;
		background-size: contain;

		@include media-breakpoint-up(sm) {
			width: $logo_width-sm;
			height: $logo_height-sm;			
		}

	}

	.branding_slogan {
		display: block;
		margin: 0 auto;
		width: 100%;
		max-width: $logo_width;
	}

	.branding_best {
		display: block;
		width: 200px;
		margin: 0 auto 15px;
		font-size: 14px;
		font-weight: 800;
		line-height: 1em;
		text-align: center;
		text-decoration: none;
		color: #333 !important;
		img {
			max-width: 150px;
			margin: 0 auto;
		}
	}